import { TILE_LOOK_ALTERNATIVE } from '@/project-constants'
import dynamic from 'next/dynamic'
import bannerConfigAggregator from './banner/config-aggregator'
import BannerAlternativeShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-alternative/banner-alternative-shimmer'
import BannerTextShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-text/banner-text-shimmer'
import BannerProductShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-product/banner-product-shimmer'
import BannerMultipleCtasShimmer from '/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-multiple-ctas/banner-multiple-ctas-shimmer'
import BannerText2Shimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-text-2/banner-text-2-shimmer'
import sliderHoverNavigationConfigAggregator from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/slider-hover-navigation/config-aggregator'
import BannerHoverBackgroundNavigationShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-hover-background-navigation/banner-hover-background-navigation-shimmer'
import BannerImage2Shimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-image-2/banner-image-2-shimmer'
import BannerMenuShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu-shimmer'
import BannerCategoryShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-category/banner-category-shimmer'
import productsConfigAggregator from './products/config-aggregator'
import ProductsShimmer from './products/products-shimmer'
import sliderConfigAggregator from './slider/config-aggregator'
import SliderAccordionShimmer from '/src/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/slider-accordion/slider-accordion-shimmer'

const BannerAlternative = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-alternative/banner-alternative'
		),
	{ loading: BannerAlternativeShimmer }
)

const BannerText = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-text/banner-text'
		),
	{ loading: BannerTextShimmer }
)

const BannerProduct = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-product/banner-product'
		),
	{ loading: BannerProductShimmer }
)

const BannerMultipleCtas = dynamic(
	() =>
		import('@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-multiple-ctas'),
	{ loading: BannerMultipleCtasShimmer }
)

const BannerText2 = dynamic(
	() => import('@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-text-2'),
	{ loading: BannerText2Shimmer }
)

const BannerHoverBackgroundNavigation = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-hover-background-navigation/banner-hover-background-navigation'
		),
	{ loading: BannerHoverBackgroundNavigationShimmer }
)

const BannerImage2 = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-image-2/banner-image-2'
		),
	{ loading: BannerImage2Shimmer }
)

const BannerMenu = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu'
		),
	{ loading: BannerMenuShimmer }
)

const BannerCategory = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-category/banner-category'
		),
	{ loading: BannerCategoryShimmer }
)

const BannerCategoryBackground = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-category/banner-category-background'
		),
	{ loading: BannerCategoryShimmer }
)

const BannerImageText = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-image-text/banner-image-text'
		),
	{ loading: BannerImage2Shimmer }
)

const Products = dynamic(() => import('./products'), { loading: ProductsShimmer })
const productsConfig = { configAggregator: productsConfigAggregator, component: Products }

const bannerAlternativeConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerAlternative,
}

const bannerTextConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerText,
}

const bannerProductConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerProduct,
}

const bannerMultipleCtasConfig = { component: BannerMultipleCtas, configAggregator: bannerConfigAggregator }

const bannerText2Config = {
	configAggregator: bannerConfigAggregator,
	component: BannerText2,
}

const bannerHoverBackgroundNavigationConfig = {
	configAggregator: sliderHoverNavigationConfigAggregator,
	component: BannerHoverBackgroundNavigation,
}

const bannerImage2Config = {
	configAggregator: bannerConfigAggregator,
	component: BannerImage2,
}

const bannerMenuConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerMenu,
}

const bannerCategoryConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerCategory,
}

const bannerCategoryBackgroundConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerCategoryBackground,
}

const productsTileLookAlternativeConfig = {
	...productsConfig,
	configAggregator: (node, props) => ({
		...productsConfigAggregator(node, props),
		tileVariant: TILE_LOOK_ALTERNATIVE,
	}),
}

const bannerImageTextConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerImageText,
}

const SliderAccordion = dynamic(
	() => import('@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/slider-accordion'),
	{ loading: SliderAccordionShimmer }
)

const sliderAccordionConfig = { component: SliderAccordion, configAggregator: sliderConfigAggregator }

const customContentTypes = {
	'banner-alternative': bannerAlternativeConfig,
	'banner-text': bannerTextConfig,
	'banner-product': bannerProductConfig,
	'banner-multiple-ctas': bannerMultipleCtasConfig,
	'banner-text-2': bannerText2Config,
	'slider-hover-background-navigation': bannerHoverBackgroundNavigationConfig,
	'banner-image-2': bannerImage2Config,
	'banner-menu': bannerMenuConfig,
	'products-tile-look-alternative': productsTileLookAlternativeConfig,
	'slider-accordion': sliderAccordionConfig,
	'banner-category': bannerCategoryConfig,
	'banner-category-background': bannerCategoryBackgroundConfig,
	'slide-image-text': bannerImageTextConfig,
}

export default customContentTypes
